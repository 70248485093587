import styled from "styled-components";

export const Container = styled.div`
  margin-left: 10px;
  /* flex: 1 1; */
  &:first-of-type {
    margin-left: 0;
  }

  font-size: 0.85rem !important;
  font-weight: bold !important;
  margin-bottom: 0.3rem !important;
  padding-left: 3px;
  margin-top: 0 !important;
`;
