import CountUp from "react-countup";
import styled from "styled-components";

export const Container = styled(CountUp)`
  color: #f0f2f5;
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
  margin-left: 10px;
`;
