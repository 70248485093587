import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";
import React from "react";

const address: any = {
  development: "https://node1.goknown.app/",
  staging: "https://backend.goknown.app/",
  production:
    process.env.REACT_APP_PRODUCTION_API || "https://node1.goknown.app/",
  balance: "https://backend.goknown.app/",
};
class Axios {
  public api = {} as AxiosInstance;

  constructor() {
    // console.log(process.env.NODE_ENV);
    // eslint-disable-next-line react-hooks/rules-of-hooks

    const baseURL = address[process.env.REACT_APP_ENVIRONMENT || "production"];

    this.api = axios.create({
      // baseURL: "http://localhost:3333/",
      baseURL,
      // baseURL: "https://backend.dappgenius.app/",
      // baseURL: "http://localhost:3333/",
      timeout: 10000,
    });

    this.interceptors();
  }

  interceptors() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const { signOut } = useAuth();
    // eslint-disable-next-line react-hooks/rules-of-hooks

    this.api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error.response?.status === 403) {
          localStorage.removeItem("@GoKnown:token");
          localStorage.removeItem("@GoKnown:user");
          window.location.href = "/";
        }
        //   localStorage.removeItem("@GoKnown:token");
        //   localStorage.removeItem("@GoKnown:user");
        //   // window.location.href = "/";
        //   let _error = {
        //     response: {
        //       status: error.response?.status || 500,
        //       data: {
        //         error: error.response
        //           ? error.response.data["message"] ||
        //             error.response.data["error"]
        //           : "Internal Server Error",
        //       },
        //     },
        //   };
        //   return Promise.reject(_error);
        // } else {
        const errorFormatted = {
          response: {
            status: error.response?.status || 500,
            data: {
              error: error.response
                ? error.response.data.message || error.response.data.error
                : "Internal Server Error",
            },
          },
        };
        return Promise.reject(errorFormatted);
        // }
      }
    );
    // this.api.interceptors.response.use(
    //   (response: AxiosResponse) => response,
    //   (error: AxiosError) => {
    //     let _error = {
    //       response: {
    //         status: error.response?.status || 500,
    //         data: {
    //           error: error.response
    //             ? error.response.data["message"] || error.response.data["error"]
    //             : "Internal Server Error",
    //         },
    //       },
    //     };

    //     if (_error.response.status === 403) {
    //       localStorage.removeItem("@GoKnown:token");
    //       localStorage.removeItem("@GoKnown:user");
    //       _error = {
    //         response: {
    //           status: 403,
    //           data: {
    //             error: "Session expired, sign in again",
    //           },
    //         },
    //       };
    //     }
    //     console.log("----", _error);
    //     return Promise.reject(_error);
    //   }
    // );
  }
}

export default new Axios().api;
