import React, { createContext, useContext } from "react";
import io, { Socket } from "socket.io-client";
// import { useAuth } from "./auth";

interface ISocketContextData {
  socket: Socket;
}

const SocketContext = createContext<ISocketContextData>(
  {} as ISocketContextData
);

const SocketProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const socketIO = io("https://node1.dappgenius.app", { path: "/socket.io" });
  // const socketIO = io("http://localhost:3333", { path: "/socket.io" });

  return (
    <SocketContext.Provider
      value={{
        socket: socketIO,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

function useSocket(): ISocketContextData {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within an AuthProvider");
  }

  return context;
}

export { SocketProvider, useSocket };

// export const socket = io("http://localhost:3333", { path: "/socket.io" });
// export const SocketContext = createContext(socket);
