import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh;
  width: 100%;
  background-color: transparent !important;
  position: relative;
`;
