import { format, parseISO } from "date-fns";
import { off } from "process";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiInfo } from "react-icons/fi";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import AsyncSelect from "../../components/AsyncSelect";
import Button from "../../components/Button";
import ButtonBack from "../../components/ButtonBack";
import ListItemLoader from "../../components/ContentLoader/LisItemLoader";
import { useAuth } from "../../hooks/auth";
import { useDialog } from "../../hooks/dialog";
import api from "../../services/api";
import { Avatar } from "../../styles/global";
import dls from "../../utils/getDLs";
import { FilterList } from "../DigitalAssets/styles";
import AdminLog from "./AdminLog";
import { logsFake } from "./fakeData";
import { Container, Content, LogInfo, Schedule } from "./styles";
import UserLog from "./UserLog";

export type IUserItem = {
  id: string;
  sync_id: string;
  name: string;
  email?: string;
  value?: string;
  label?: string;
  avatar?: string;
  avatar_url?: string;
  firstName?: string;
};

type IUser = {
  id: string;
  name: string;
  email: string;
  role: string;
  avatar_url: string;
};

export type IAuditLogsItem = {
  id: string;
  user_id: string;
  sync_id: string;
  action: string;
  dapp: string;
  dapp_token: string;
  dapp_token_sync_id: string;
  nodes: any;
  leader_node: string;
  created_at: string;
  user: IUser;
};

export type INodesItem = {
  outcome: string;
  node: string;
  message?: any;
  created_at: string;
};

type IFilter = {
  limit: number;
  offset: number;
  logType?: string;
};

const Auditlogs: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [selectedUser, setSelectedUser] = useState<IUserItem>();
  const [filter, setFilter] = useState<IFilter>({
    limit: 100,
    offset: 0,
    logType: "All",
  });

  // const [asyncLoading, setAsyncLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState<IAuditLogsItem[]>([]);
  const [hasMore, setHasMore] = useState(false);

  const { user } = useAuth();
  const { showDialog } = useDialog();

  const formattedDate = useCallback(
    (date: string) => format(parseISO(date), "M/d/yyyy h:mm:s a"),
    []
  );

  const downloadLog = (log: any) => {
    // create file in browser
    const fileName = `${log.user.name} Audit Logs ${new Date()}`;
    const json = JSON.stringify(log, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = `${fileName}.json`;
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const LogInfoTemplate = useCallback(
    (log: any) => {
      return (
        <LogInfo>
          <ul>
            <li>
              <strong>User: {log.user.name}</strong>
            </li>
            <li>
              <strong>Token ID:</strong> <p>{log.dapp_token}</p>
            </li>
            <li>
              <strong>Action:</strong> <p>{log.action}</p>
            </li>
            <li>
              <strong>Leader Node:</strong>
              <p>{log.leader_node}</p>
            </li>

            <li>
              <strong>Outcome:</strong>
              <p>{log.nodes.find((l: any) => l.name === log.name).outcome}</p>
            </li>

            <li
              style={{
                paddingTop: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <strong style={{ marginRight: "1rem" }}>NODES:</strong>
              <ul>
                {log.nodes.map((node: any) => (
                  <li>
                    <strong>
                      {node.node} - {node.outcome}
                    </strong>
                    <p>{formattedDate(node.created_at)}</p>
                  </li>
                ))}
              </ul>
            </li>
          </ul>

          <Button onClick={() => downloadLog(log)}>Download JSON</Button>
        </LogInfo>
      );
    },

    [formattedDate]
  );

  const handleLogInfo = useCallback(
    (log: any) => {
      console.log(log);
      showDialog({
        cancelButtonText: "Close",
        showCancelButton: false,
        showConfirmButton: false,
        title: `Information`,
        html: LogInfoTemplate(log),
      });
    },
    [LogInfoTemplate, showDialog]
  );

  const handleSelectedUser = useCallback((value: any) => {
    value
      ? setSelectedUser({
          id: value.id,
          name: value.label,
          firstName: value.firstName,
          ...value,
        })
      : setSelectedUser(undefined);
  }, []);

  const handleSelectFilter = useCallback(
    async (value: any) => {
      // setFilter({ ...filter, logType: value });

      const response = await api.get<IAuditLogsItem[]>("/auditlogs", {
        params: {
          filtered_user_syncid: selectedUser?.sync_id,
          offset: 0,
          limit: 100,
          logType: value,
        },
      });
      setLogs(response.data);
    },
    [selectedUser?.sync_id]
  );

  const loadLogs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IAuditLogsItem[]>("/auditlogs", {
        params: {
          filtered_user_syncid: selectedUser?.sync_id,
          offset: filter.offset,
          limit: filter.limit,
        },
      });

      if (response.data.length === 0) {
        setHasMore(false);
        return; // Para evitar mais processamento se não houver dados
      }

      console.log(response.data);

      // Atualiza logs com base no valor anterior (estado funcional)
      setLogs((prevLogs) => [...prevLogs, ...response.data]);

      // Atualiza o filtro incrementando o offset
      setFilter((prevFilter) => ({
        ...prevFilter,
        offset: prevFilter.offset + prevFilter.limit,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [filter.offset, filter.limit, selectedUser]);

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  return (
    <Container mobileHeight={100} height={100}>
      <header>
        <div>
          <ButtonBack
            title="Audit Logs"
            mobileTitle="Audit Logs"
            goTo={"/dashboard"}
          />
          {/* <h1>Audit Logs</h1> */}
        </div>
      </header>
      <Content>
        <Schedule>
          {user.role === "admin" ? (
            <AdminLog
              hasMore={hasMore}
              loading={loading}
              logsData={logs}
              handleSelectedUser={handleSelectedUser}
              handleSelectFilter={handleSelectFilter}
              selectedUser={selectedUser}
              loadLogs={loadLogs}
            />
          ) : (
            <UserLog loading={loading} logsData={logs} />
          )}

          {/* {loading ? (
            <ListItemLoader />
          ) : (
            <>
              {renderFormFilter()}

              {!loading && logs.length === 0 ? (
                <header>
                  <p>No logs found</p>
                </header>
              ) : (
                <List>
                  <ListHeader>
                    <Column flex={0.3}>User</Column>
                    <Column></Column>
                    <Column flex={2}>DApp</Column>
                    <Column flex={0.5}>Action</Column>
                    <Column flex={0.3}></Column>
                  </ListHeader>
                  {!loading &&
                    logs.map((log) => (
                      <ListItem key={log.id}>
                        <Column flex={0.3}>
                          <Avatar round name={log.user.name} />
                        </Column>
                        <Column>
                          <p>{log.user.name}</p>
                          <span>{log.user.role}</span>
                        </Column>

                        <Column flex={2}>
                          <p>{log.dapp}</p>
                          <FlagToken>{log.dapp_token_sync_id}</FlagToken>
                        </Column>

                        <Column flex={0.5}>
                          <p>{log.action}</p>
                        </Column>
                        <Column flex={0.3}>
                          <button onClick={(event) => handleLogInfo(log)}>
                            <FiInfo />
                          </button>
                        </Column>
                      </ListItem>
                    ))}
                </List>
              )}
            </>
          )} */}
        </Schedule>
      </Content>
    </Container>
  );
};

export default Auditlogs;
