import React, { useCallback, useRef, useState } from "react";
import { FiArrowLeft, FiMail, FiUser, FiLock } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import logoImg from "../../assets/logo.svg";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import getValidationErrors from "../../utils/getValidationErrors";
import { Container, Content, AnimationContainer, Background } from "./styles";

interface ISignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ISignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(),
          email: Yup.string().required().email(),
          password: Yup.string().min(6),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post("/users", data);
        addToast({
          type: "success",
          title: "Registration completed",
          timeout: 8000,
          description:
            "You will receive a PIN in your email to activate your account when logging into your account",
        });
        history.push("/");
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: "error",
          title: "Error while creating account",
          description: err.response.data.error || err.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history]
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="GoBarber" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Create new account</h1>

            <Input
              name="name"
              icon={FiUser}
              placeholder="Full name"
              isLoading={loading}
            />
            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              isLoading={loading}
            />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Password"
              isLoading={loading}
            />

            <Button type="submit" isLoading={loading}>
              Create Account
            </Button>
          </Form>
          <Link to="/">
            <FiArrowLeft />
            Back to sign in
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
