import React, { createContext, useCallback, useContext, useState } from "react";
import { v4 as uuid } from "uuid";

import ToastContainer from "../components/ToastContainer";

export interface IToastMessage {
  id: string;
  type?: "success" | "error" | "info";
  title: string;
  timeout?: number;
  description?: string;
}

interface IToastContextData {
  addToast(message: Omit<IToastMessage, "id">): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<IToastContextData>({} as IToastContextData);

const ToastProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [messages, setMessages] = useState<IToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, description, timeout }: Omit<IToastMessage, "id">) => {
      const id = uuid();

      const toast = { id, type, title, description, timeout };

      setMessages((state) => [...state, toast]);
    },
    []
  );

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast(): IToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within a Toastprovider");
  }

  return context;
}

export { ToastProvider, useToast };
