// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import { enUS } from "date-fns/locale";
import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiFillFolder } from "react-icons/ai";
import { FiCalendar, FiEdit, FiPower, FiUser } from "react-icons/fi";
import { useHistory, Link } from "react-router-dom";

import logoImg from "../../assets/logo-white.svg";
// import noTransactions from "../../assets/notransactions.svg";
// import { ReactComponent as Suffix } from "../../assets/suffix.svg";
// import Button from "../../components/Button";
// import TransactionLoader from "../../components/ContentLoader/TransactionsLoader";
// import CountUp from "../../components/CountUp";
import Asset, { AssetTypes } from "../../components/Asset";
import { TransactionsLoader } from "../../components/ContentLoader";
import DLs from "../../components/DLs";
import { useAuth } from "../../hooks/auth";
import { useDialog } from "../../hooks/dialog";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import { Avatar } from "../../styles/global";
// import formatValue from "../../utils/formatValue";

// get DL`s
import Defaultdls from "../../utils/getDLs";
import { IFoldersItem, IUserData } from "../DataForms/store";
import {
  ContentDigitalAssets,
  Flag,
  FolderAsset,
  InfoDigitalAssets,
} from "../DigitalAssets/styles";
import {
  Container,
  Header,
  HeaderContent,
  Profile,
  Content,
  Schedule,
  Section,
  SubHeader,
  Transaction,
  InfoTransaction,
  BalanceTransaction,
  TransactionInfo,
  TransactionMessageInfo,
} from "./styles";
// import { useSocket } from "../../hooks/socket";

interface IUser {
  id: string;
  name: string;
  avatar: string;
  avatar_url: string;
  email: string;
}

interface IDigitalAssetsItem {
  id: string;
  sync_id: string;
  asset_url: string;
  mimetype: AssetTypes;
  name: string;
  description?: string;
  privacy: "private" | "public";
  created_at: string;
  folder?: {
    name: string;
  };
}

interface IDashboardItem {
  adminAssets: {
    id: string;
    sync_id: string;
    name: string;
    user: IUserData;
    shared?: boolean;
    assets: IDigitalAssetsItem[];
  };
}

// interface ITransactionItem {
//   id: string;
//   category: "charge" | "transaction";
//   transactionType: "sent" | "received";
//   status: "pending" | "approved" | "unapproved" | "failed";
//   amount: number;
//   balance: number;
//   formattedAmount: string;
//   formattedBalance: string;
//   created_at: string;
//   message?: string;
//   date: string;
//   touser: IUser;
//   fromuser: IUser;
// }

// interface IResponseTransactions {
//   transactions: ITransactionItem[];
//   current_balance: number;
// }

const Dashboard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState<IDashboardItem>(
    {} as IDashboardItem
  );
  // const [transactions, setTransactions] = useState<ITransactionItem[]>([]);
  const history = useHistory();

  const { addToast } = useToast();
  const { showDialog } = useDialog();
  const { signOut, user, updateCurrentBalance } = useAuth();
  // const { disconnectSocket, connectSocket } = useSocket();

  // nunca criar variaveis ou manipular valores como uma variavel normal
  // para calculos usar useMemo

  const selectedDateAsText = useMemo(() => {
    return format(selectedDate, "MMMM' ' dd',' y", {
      locale: enUS,
    });
  }, [selectedDate]);

  // const selectedWeekDay = useMemo(
  //   () => format(selectedDate, "cccc", { locale: enUS }),
  //   [selectedDate]
  // );

  // const description = useCallback((transaction: ITransactionItem) => {
  //   if (transaction.transactionType === "sent") {
  //     return `to ${transaction.touser.name}`;
  //   }
  //   if (transaction.transactionType === "received") {
  //     if (transaction.category === "charge") {
  //       return "from Token Issuer";
  //     }
  //     return `from ${transaction.touser.name}`;
  //   }
  // }, []);

  // const formattedDateTransaction = useCallback(
  //   (date: string) => format(parseISO(date), "M/d/yyyy h:mm a"),
  //   []
  // );

  // const avatar = useCallback((transaction: ITransactionItem) => {
  //   if (transaction.transactionType === "sent") {
  //     return (
  //       <Avatar
  //         name={transaction.fromuser.name}
  //         src={transaction.fromuser.avatar_url}
  //         round
  //       />
  //     );
  //   }
  //   if (transaction.transactionType === "received") {
  //     if (transaction.category === "charge") {
  //       return "";
  //     }
  //     return (
  //       <Avatar
  //         name={transaction.touser.name}
  //         src={transaction.touser.avatar_url}
  //         round
  //       />
  //     );
  //   }
  // }, []);

  // const handleGoTo = useCallback((to: string) => history.push(to), [history]);

  // const transactionTemplate = useCallback(
  //   (transaction: ITransactionItem) => {
  //     return (
  //       <TransactionInfo>
  //         <ul>
  //           <li>
  //             <strong>Date:</strong>
  //             <p>{formattedDateTransaction(transaction.created_at)}</p>
  //           </li>
  //           <li>
  //             <strong>Transaction ID:</strong> <p>{transaction.id}</p>
  //           </li>
  //           {transaction.category === "transaction" && (
  //             <li>
  //               <strong>From:</strong> <p>{transaction.fromuser.name}</p>
  //             </li>
  //           )}
  //           <li>
  //             <strong>To:</strong> <p>{transaction.touser.name}</p>
  //           </li>
  //           <li>
  //             <strong>Amount:</strong> <p>{formatValue(transaction.amount)}</p>
  //           </li>
  //         </ul>
  //         {transaction.message && (
  //           <TransactionMessageInfo>
  //             <label>Note</label>
  //             <p>{transaction.message}</p>
  //           </TransactionMessageInfo>
  //         )}
  //       </TransactionInfo>
  //     );
  //   },

  //   []
  // );

  // const handleReceiptTransaction = useCallback(
  //   (transaction: ITransactionItem) => {
  //     showDialog({
  //       cancelButtonText: "Close",
  //       showCancelButton: true,
  //       showConfirmButton: false,
  //       title: `${transaction.category} info`,
  //       html: transactionTemplate(transaction),
  //     });
  //   },
  //   [showDialog, transactionTemplate]
  // );

  // useEffect(() => {
  //   setLoading(true);
  //   api
  //     .get<IResponseTransactions>("/me/transactions")
  //     .then((response) => {
  //       setTransactions(
  //         response.data.transactions.map((transaction) => ({
  //           ...transaction,
  //           date: format(parseISO(transaction.created_at), "MM/dd/yy"),
  //           formattedAmount: formatValue(transaction.amount),
  //           formattedBalance: formatValue(transaction.balance),
  //         }))
  //       );
  //       updateCurrentBalance(response.data.current_balance, false);
  //     })
  //     .catch((err) =>
  //       addToast({ type: "error", title: "error", description: err.message })
  //     )
  //     .finally(() => setLoading(false));
  // }, [addToast, updateCurrentBalance]);

  // useEffect(() => {
  //   if (
  //     transactions.length > 0 &&
  //     user.current_balance < transactions[0].balance
  //   ) {
  //     updateCurrentBalance(transactions[0].balance, false);
  //   }
  // }, [transactions, updateCurrentBalance, user.current_balance]);

  // useEffect(() => {
  //   connectSocket(user.sync_id);
  // }, [connectSocket, user.sync_id]);

  // const renderUnRead = useCallback(
  //   (name: string) => {
  //     return (
  //       name === "Messenger" && !!user.unread && <span>{user.unread}</span>
  //     );
  //   },
  //   [user.unread]
  // );
  const handleGoTo = useCallback(
    (to: string) =>
      history.push(to, {
        oldPage: "/dashboard",
        folderId: null,
      }),
    [history]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get("/me/dashboard")
      .then((response) => {
        setDashboard(response.data);
      })
      .catch((err) =>
        addToast({ type: "error", title: "Error", description: err.message })
      )
      .finally(() => setLoading(false));
  }, [addToast]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <div className="logo">
            <img src={logoImg} alt="DAppGenius" />
          </div>

          <Profile>
            {/* <img
              src="https://avatars2.githubusercontent.com/u/3733634?s=460&u=7a471a9949ece0d3c859feaf46061b2edbab7771&v=4"
              alt={user.name}
            /> */}
            <Avatar
              name={user.name}
              src={user.avatar_url}
              borderColor="#53bf99"
              round
            />

            <div>
              <span>Welcome,</span>
              <Link to="/profile">
                <strong>{user.name}</strong>
              </Link>
            </div>
          </Profile>
          <Link to="/profile">
            <FiUser />
          </Link>
          <button
            type="button"
            onClick={() => {
              // disconnectSocket();
              signOut();
            }}
          >
            <FiPower />
          </button>
        </HeaderContent>
      </Header>
      <SubHeader />
      <Content>
        {/* <DLs>
          <h1>My DL Apps</h1>
          <p>
            <span>{selectedDateAsText || ""}</span>
          
          </p>
          <CardContent>
            {Defaultdls.map((dl, index) => (
              <Card key={index} onClick={() => handleGoTo(dl.url)}>
                <img src={dl.icon} alt="dls" width="72" />
                <p>{dl.name}</p>
                {renderUnRead(dl.name)}
              </Card>
            ))}
          </CardContent>
        </DLs> */}

        <DLs
          title="My DL Apps"
          subtitle={selectedDateAsText}
          dls={Defaultdls}
        />

        {/* <Schedule>
          <h1>Recent Transactions</h1>
          <Section>
            {loading && <TransactionLoader />}
            {!loading && transactions.length === 0 && (
              <header>
                <p>No Transactions found</p>
                <img src={noTransactions} alt="no transactions" />
              </header>
            )}
            {!loading &&
              transactions.map((transaction) => (
                <Transaction
                  key={transaction.id}
                  type={transaction.transactionType}
                  onClick={() => handleReceiptTransaction(transaction)}
                  status={transaction.status}
                >
                  <span>
                    <FiCalendar /> {transaction.date}
                  </span>
                  <div>
                    {avatar(transaction)}
                    <section>
                      <InfoTransaction status={transaction.status}>
                        <span>
                          <h4>{transaction.transactionType}</h4>
                          <h6>
                            {["unapproved", "failed"].includes(
                              transaction.status
                            )
                              ? `- ${transaction.status}`
                              : ""}
                          </h6>
                        </span>
                        <strong>{description(transaction)}</strong>
                      </InfoTransaction>
                      <BalanceTransaction
                        status={transaction.status}
                        type={transaction.transactionType}
                      >
                        <div>
                          <Suffix />
                          <h2>{transaction.formattedAmount}</h2>
                        </div>
                        <strong>{transaction.formattedBalance}</strong>
                      </BalanceTransaction>
                    </section>
                  </div>
                </Transaction>
              ))}
          </Section>
        </Schedule> */}

        <Schedule>
          <h1>{!loading && dashboard?.adminAssets?.name}</h1>

          <AnimatePresence>
            {loading && <TransactionsLoader />}

            {!loading && !dashboard.adminAssets?.id && (
              <Section>
                <header>
                  <p>No data found</p>
                  {/* <img src={noTransactions} alt="no transactions" /> */}
                </header>
              </Section>
            )}

            {!loading &&
              dashboard?.adminAssets?.assets?.length > 0 &&
              dashboard?.adminAssets?.assets.map((asset) => (
                <ContentDigitalAssets key={asset.sync_id}>
                  <div>
                    <Asset
                      url={asset.asset_url}
                      name={asset.name}
                      type={asset.mimetype}
                      onClick={() =>
                        handleGoTo(`/digitalassets/${asset.sync_id}/preview`)
                      }
                    />
                    <InfoDigitalAssets
                      onClick={() =>
                        handleGoTo(`/digitalassets/${asset.sync_id}/preview`)
                      }
                    >
                      <h4>{asset.name}</h4>
                      <strong>{asset.created_at}</strong>
                    </InfoDigitalAssets>

                    <section
                      onClick={() =>
                        handleGoTo(`/digitalassets/${asset.sync_id}/preview`)
                      }
                    >
                      {/* <Flag
                        color={asset.privacy === "public" ? "green" : "red"}
                      >
                        <strong>{asset.privacy}</strong>
                      </Flag> */}

                      {/* <FolderAsset>
                        {asset.folder && (
                          <>
                            <AiFillFolder size={12} />
                            {asset.folder.name}
                          </>
                        )}
                      </FolderAsset> */}
                    </section>
                    {user.role === "admin" && (
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          handleGoTo(`/digitalassets/${asset.sync_id}/edit`);
                        }}
                      >
                        <FiEdit />
                      </button>
                    )}
                  </div>
                </ContentDigitalAssets>
              ))}
          </AnimatePresence>
        </Schedule>
      </Content>
    </Container>
  );
};

export default Dashboard;
