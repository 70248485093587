import React from "react";
import { Switch } from "react-router-dom";

import Auditlogs from "../pages/Auditlogs";
import BuyTokens from "../pages/BuyTokens";
import Changelog from "../pages/Changelog";
import Chat from "../pages/Chats";
import ConfirmEmail from "../pages/ConfirmEmail";
import Dashboard from "../pages/Dashboard";
import DataForm from "../pages/DataForms";
import EditDataForm from "../pages/DataForms/edit";
import DataFormCollect from "../pages/DataForms/FormCollect";
import DataFormRecord from "../pages/DataForms/FormRecord";
import DataFormStructure from "../pages/DataForms/FormStructure";
import CreateDataForm from "../pages/DataForms/store";
import DigitalAssets from "../pages/DigitalAssets";
import DigitalAssetsCreate from "../pages/DigitalAssets/create";
import DigitalAssetsEdit from "../pages/DigitalAssets/edit";
import Folder from "../pages/DigitalAssets/Folder";
import FolderPreview from "../pages/DigitalAssets/Folder/preview";
import DigitalAssetsPreview from "../pages/DigitalAssets/preview";
import ExternalFormBuilder from "../pages/ExternalFormBuilder";
import ForgotPassword from "../pages/ForgotPassword";
import Groups from "../pages/Groups";
import GroupsStore from "../pages/Groups/store";
import InviteUser from "../pages/InviteUser";
import Laboratory from "../pages/Laboratory";
import Messenger from "../pages/Messenger";
import Organizations from "../pages/Organizations";
import EditOrganization from "../pages/Organizations/edit";
import OrgazinationsGroupsAdmin from "../pages/Organizations/GroupAdmin/Invitation";
import OrganizationsGroups from "../pages/Organizations/Groups";
import EditOrganizationsGroups from "../pages/Organizations/Groups/edit";
import GroupUsers from "../pages/Organizations/GroupUsers/Invitation";
import OrganizationsRooms from "../pages/Organizations/Rooms";
import RoomsDashboard from "../pages/Organizations/Rooms/Dashboard";
import EditOrganizationsRooms from "../pages/Organizations/Rooms/edit";
import Profile from "../pages/Profile";
import ResetPassword from "../pages/ResetPassword";
import SendToken from "../pages/SendTokens";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Transaction from "../pages/Transaction";
import { TwoFactorSMS } from "../pages/TwoFactorSMS";
import Route from "./Route";

// import Guest from "../pages/Guest";

const Routes: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/formbuilder" exact component={ExternalFormBuilder} />

    {/* <Route path="/guest" exact component={Guest} /> */}
    <Route path="/signup" exact component={SignUp} />
    <Route path="/confirm-email-denied" component={ConfirmEmail} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/changelog" component={Changelog} />

    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/users" component={InviteUser} isPrivate />
    <Route path="/trasactions" component={Transaction} isPrivate />
    <Route path="/digitalassets" exact component={DigitalAssets} isPrivate />
    <Route
      path="/digitalassets/rooms/:idRom"
      exact
      component={DigitalAssets}
      isPrivate
    />
    <Route
      path="/digitalassets/new"
      exact
      component={DigitalAssetsCreate}
      isPrivate
    />
    <Route
      path="/digitalassets/:id/preview"
      exact
      component={DigitalAssetsPreview}
      isPrivate
    />
    <Route
      path="/digitalassets/:id/edit"
      exact
      component={DigitalAssetsEdit}
      isPrivate
    />

    <Route path="/folder" exact component={Folder} isPrivate />
    <Route path="/folder/:id/edit" exact component={Folder} isPrivate />
    <Route
      path="/folder/:id/preview"
      exact
      component={FolderPreview}
      isPrivate
    />

    <Route path="/groups" exact component={Groups} isPrivate />
    <Route path="/groups/:id/edit" component={GroupsStore} isPrivate />
    <Route path="/groups/new" exact component={GroupsStore} isPrivate />

    <Route path="/sendtokens" exact component={SendToken} isPrivate />
    <Route path="/buytokens" exact component={BuyTokens} isPrivate />
    <Route path="/confirm-email" exact component={ConfirmEmail} isPrivate />
    <Route path="/configure" exact component={TwoFactorSMS} isPrivate />
    <Route path="/profile" component={Profile} isPrivate />

    <Route path="/dataforms" exact component={DataForm} isPrivate />
    <Route path="/dataforms/new" exact component={CreateDataForm} isPrivate />
    <Route
      path="/dataforms/:id/edit"
      exact
      component={EditDataForm}
      isPrivate
    />
    <Route
      path="/dataforms/:id/structure"
      exact
      component={DataFormStructure}
      isPrivate
    />

    <Route
      path="/dataforms/:id/collect"
      exact
      component={DataFormCollect}
      isPrivate
    />

    <Route
      path="/dataforms/:id/records"
      exact
      component={DataFormRecord}
      isPrivate
    />

    {/* <Route path="/chat" exact component={Chat} isPrivate /> */}
    <Route path="/messenger" exact component={Messenger} isPrivate />
    <Route path="/auditlogs" exact component={Auditlogs} isPrivate />

    <Route path="/organizations" exact component={Organizations} isPrivate />
    <Route
      path="/organizations/new"
      exact
      component={EditOrganization}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/edit"
      exact
      component={EditOrganization}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groupsadmins"
      exact
      component={OrgazinationsGroupsAdmin}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups"
      exact
      component={OrganizationsGroups}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/new"
      exact
      component={EditOrganizationsGroups}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/edit"
      exact
      component={EditOrganizationsGroups}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms"
      exact
      component={OrganizationsRooms}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/new"
      exact
      component={EditOrganizationsRooms}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/edit"
      exact
      component={EditOrganizationsRooms}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/dashboard"
      exact
      component={RoomsDashboard}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/users"
      exact
      component={GroupUsers}
      isPrivate
    />

    {/* rooms DApps */}
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/dataforms"
      exact
      component={DataForm}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/dataforms/new"
      exact
      component={CreateDataForm}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/dataforms/:id/edit"
      exact
      component={EditDataForm}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/dataforms/:id/structure"
      exact
      component={DataFormStructure}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/dataforms/:id/collect"
      exact
      component={DataFormCollect}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/dataforms/:id/records"
      exact
      component={DataFormRecord}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/messenger"
      exact
      component={Messenger}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/digitalassets"
      exact
      component={DigitalAssets}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/digitalassets/rooms/:idRom"
      exact
      component={DigitalAssets}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/digitalassets/new"
      exact
      component={DigitalAssetsCreate}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/digitalassets/:id/preview"
      exact
      component={DigitalAssetsPreview}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/digitalassets/:id/edit"
      exact
      component={DigitalAssetsEdit}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/folder"
      exact
      component={Folder}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/folder/:id/edit"
      exact
      component={Folder}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/folder/:id/preview"
      exact
      component={FolderPreview}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/trasactions"
      component={Transaction}
      isPrivate
    />

    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/sendtokens"
      exact
      component={SendToken}
      isPrivate
    />
    <Route
      path="/organizations/:idOrganization/groups/:idGroup/rooms/:idRoom/buytokens"
      exact
      component={BuyTokens}
      isPrivate
    />

    <Route path="/labs" exact component={Laboratory} isPrivate />
  </Switch>
);

export default Routes;
